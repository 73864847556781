import React from 'react';

import { AttendanceQRScreen, LoginPage, ShiftScreen, CoursesScreen, FormsScreen, TasksScreen, IssuesScreen } from '@know/pages';
import { KNOWProvider, KNOWRoutes, KNOWDBContext } from '@know/ui';
import appJson from './app.config';

globalThis.process = {
  ...globalThis?.process,
  env: {
    ...globalThis?.process?.env,
  }
}

export default function App() {
  const hasAccessToModule = () => {
    return true;
  };

  return (
    <KNOWProvider isDark={true} hasAccessToModule={hasAccessToModule} enableNotifications={true}>
      <HomePage />
    </KNOWProvider>
  );
}

const HomePage = () => {
  const version = appJson.expo.version || '1.0.0';
  const { currentUser } = React.useContext(KNOWDBContext);

  return currentUser ? (
    <>
      <CoursesScreen />
      <FormsScreen />
      <TasksScreen />
      <IssuesScreen />
      <ShiftScreen />
      <AttendanceQRScreen />
      <KNOWRoutes version={version} />
    </>
  ) : (
    <LoginPage />
  );
};
