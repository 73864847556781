export default {
  "name": "KNOW Web App",
  "displayName": "KNOW Web App",
  "expo": {
    "name": "KNOW",
    "slug": "know-app",
    "description": "KNOW App",
    "privacy": "public",
    "version": "1.8.0",
    "platforms": [
      "ios",
      "android",
      "web"
    ],
    "ios": {
      "supportsTablet": true
    },
    "assetBundlePatterns": [
      "**/*"
    ],
    "web": {
      "favicon": "favicon.png"
    },
    extra: {
      useLocalWebview: process.env.USE_LOCAL_WEBVIEW
    }
  }
}
